import React from 'react';
import { Steps, Image } from 'antd';
import '../index.css';

import { FormattedMessage } from 'react-intl';

const { Step } = Steps;

const Home = () => (

    <div  style={{ display: 'flex', flexDirection: 'row', width: '100%', color: '#ffffff' }}>

      <div style={{ flex: 1, padding: '10px 0px' }}>
               
        <h2></h2>

        <Steps direction="vertical" current={-1} style={{paddingTop: '40px'}}>
        <Step
          title={<FormattedMessage id="withdrawToMetamask" />}
          description={
            <FormattedMessage id="withdrawToMetamaskDescription" />
          }
        />
        <Step
          title={<FormattedMessage id="eonNetworkToMainnet" />}
          description={
            <span>
              <FormattedMessage 
                id="eonNetworkToMainnetDescription" 
                values={{ 
                  eonWebsite: (
                    <a href="https://eon.horizen.io/app/transfer" target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="eonWebsiteLinkText" />
                    </a>
                  )
                }} 
              />
            </span>
          }
        />
        </Steps>
      </div>

      <div style={{ flex: 1, padding: '10px' }}>
        <Image width={560} height={560} src="./img/transfer.png" />

            </div>
    </div>
);

export default Home;
