import React from 'react';
import { ConfigProvider, Layout } from 'antd';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MetamaskProvider } from './context/MetamaskContext';
import HeaderComponent from './components/Header';
import FooterComponent from './components/Footer';
import MenuComponent from './components/Menu';
import Home from './pages/Home';
import Delegate from './pages/Delegate';
import Claim from './pages/Claim';
import Withdraw from './pages/Withdraw';
import Transfer from './pages/Transfer';
import Query from './pages/Query';
import Banner from './components/Banner';
import './index.css';

import { IntlProvider } from 'react-intl';
import en from './locales/en.json';
import zh from './locales/zh.json';
const messages = { en, zh };

let language = 'en'; // 设置默认语言为 'en'

if (navigator.language) {
  const langArray = navigator.language.split(/[-_]/);
  if (langArray.length > 0 && messages[langArray[0]]) {
    language = langArray[0];
  }
}

const { Sider, Content } = Layout;

const App = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#00b96b',
        borderRadius: 2,
        colorBgContainer: '#0C0B1B',
        colorText: '#fdfefe',
      },
      components: {
        Steps: {
          colorBgContainer: '#0C0B1B',
          colorText: '#fdfefe',
          colorPrimary: '#68d894',
          dotBorderColor: '#4a95b7',
        },
      },
    }}
  >
  <MetamaskProvider>
  <IntlProvider locale={language} messages={messages[language]}>

    <Router>
      <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Banner />
        <HeaderComponent />
        <Layout style={{ flex: 1, display: 'flex' }}>
          <Sider width={280} className="site-layout-background">
            <MenuComponent />
          </Sider>

          
          <Content style={{ flex: 1, padding: 24, margin: 0, minHeight: 280 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/delegate" element={<Delegate />} />
              <Route path="/claim" element={<Claim />} />
              <Route path="/withdraw" element={<Withdraw />} />
              <Route path="/transfer" element={<Transfer />} />
              <Route path="/query" element={<Query />} />
            </Routes>
          </Content>
        </Layout>
        <FooterComponent />
      </Layout>
    </Router>
    </IntlProvider>
    </MetamaskProvider>
  </ConfigProvider>
);

export default App;
