import React, { useState, useContext, useEffect } from 'react';
import { Steps, message } from 'antd';
import { ethers } from "ethers";
import { MetamaskContext } from '../context/MetamaskContext';
import DelegatedStaking from '../abi/DelegatedStaking.json';


import '../index.css';

import {  FormattedMessage } from 'react-intl';

// import { claimReward, calcReward } from '../contract/contract';

const { Step } = Steps;


const Home = () => {
  const { account, setAccount } = useContext(MetamaskContext);

  const [rewardResult, setRewardResult] = useState(0.0);
  const [contract, setContract] = useState(null);
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  // 初始化web3和合约
  useEffect(() => {
    const initContract = async () => {
      
      if (typeof window.ethereum === 'undefined') {
        message.error("MetaMask is not installed");
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const signer = provider.getSigner();
        const contractInstance = new ethers.Contract(contractAddress, DelegatedStaking.abi, signer);
        setContract(contractInstance);
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
      } catch (error) {
        console.error("Failed to get signer or contract: ", error.message);
        message.error(error.message);
      }
    };

    initContract();
  }, [setAccount]);


  // 计算收益
  const handleSubmitClacReward = async (e) => {
    e.preventDefault();
    if (!contract) {
      message.error("Contract is not initialized");
      return;
    }

    try {
      const result = await contract.calcReward(account);
      // const zenValue = BigInt(result.totalToClaim).toString();
      const zenValueEth = ethers.utils.formatEther(result.totalToClaim);
      setRewardResult(zenValueEth);
      message.success('查询成功, Successful.');
    } catch (error) {
      console.error("Error:", error.message);
      message.error(error.message);
      message.error('查询失败，请稍后再试');
    }
  }

  // 提取收益
  const handleSubmitClaimReward = async (e) => {
    e.preventDefault();
    if (!contract) {
      message.error("Contract is not initialized");
      return;
    }

    try {
      message.info('Metamask is opening ...', 5);
      const tx = await contract.claimReward(account);
      await tx.wait();
      setRewardResult(0);
      message.success('提取成功, Successful.', 3);
    } catch (error) {
      console.error("Error:", error.message);
      message.error('提取失败，请稍后再试' + error.message, 8);
    }
  };


  return (

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', color: '#ffffff' }}>

        <div style={{ flex: 1, padding: '10px 0px' }}>
        <h2><FormattedMessage id="claimRewardTitle" /></h2>
          <Steps direction="vertical" current={-1} style={{paddingTop: '40px'}}>
                  <Step
                    title={<FormattedMessage id="clacReward" />}
                    description={
                      <span>
                        <FormattedMessage id="clacRewardDetail" />
                      </span>
                    }
                  />
                  <Step
                    title={<FormattedMessage id="claimReward" />}
                    description={<FormattedMessage id="claimRewardDetail" />}
                  />
                  
                </Steps>
        </div>

        <div style={{ flex: 1, padding: '10px 0px' }}>
          <div style={{ width: '570px', minHeight: '700px', 
                        padding: '48px 24px', marginLeft: 'auto', marginRight: '70px',
                        backgroundColor: '#151528', borderRadius: '8px', color: '#FFFFFF' }}>
            <h2><FormattedMessage id="claimRewardTitle" /></h2>
            <p><FormattedMessage id="claimRewardTitle.description1" /></p>

            <form>
              <div className="form-group" style={{ paddingTop: '40px', marginBottom: '10px' }}>
                <label htmlFor="walletBalance">
                  <FormattedMessage id="stakeAddress" />
                </label>

                  <input
                    type="text"
                    id="walletAccount"
                    name="walletAccount"
                    value={account}
                    disabled
                    style={{ width: '98%', height: '40px', padding: '2px',  fontSize: '16px'}}
                  />
              </div>

              <div className="form-group" style={{ marginBottom: '20px' }}>
                <label htmlFor="walletBalance">
                  <FormattedMessage id="reward.calcResult" />
                </label>

                  <input
                    type="text"
                    id="rewardResult"
                    name="rewardResult"
                    value={rewardResult}
                    disabled
                    style={{ width: '98%', height: '40px', padding: '2px',  fontSize: '16px'}}
                  />
              </div>
        

                <button type="submit" className='custom-button' style={{width:'100%', height: '42px', marginTop:'20px'}} 
                        onClick={handleSubmitClacReward}>
                  <FormattedMessage id="btnText.calcRewardText" />
                </button>
                  
                <button type="submit" className='custom-button' style={{width:'100%', height: '42px', marginTop:'20px'}} 
                        onClick={handleSubmitClaimReward}>
                  <FormattedMessage id="btnText.claimReward" />
                </button>
                  
               
            </form>
          </div>
        </div>
      </div>
  );

};

export default Home;