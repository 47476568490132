import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { Table, message, Spin } from 'antd';
import { PercentageOutlined, HomeOutlined } from '@ant-design/icons';
import abi from '../abi/forger_stake_v2.json';
import { FormattedMessage } from 'react-intl';

const App = () => {
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);

  const START_INDEX = 0;
  const PAGE_SIZE = 1000;
  const contractAddress = "0x0000000000000000000022222222222222222333";

  const fetchNodes = async () => {
    setLoading(true);
    try {
      if (typeof window.ethereum === 'undefined') {
        message.error("MetaMask is not installed");
        return;
      }

      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const response = await contract.methods.getPagedForgers(START_INDEX, PAGE_SIZE).call();
      const forgers = response["1"].map((forger, index) => ({
        key: `node-${START_INDEX + index}`,
        signPubKey: forger.signPubKey,
        vrf1: forger.vrf1,
        vrf2: forger.vrf2,
        rewardShare: Number(forger.rewardShare), // 转换为数字类型
        rewardAddress: forger.reward_address,
        stakeList: null, // 初始化 stakeList 数组为 null
      }));

      // 对数据进行排序
      forgers.sort((a, b) => b.rewardShare - a.rewardShare);

      setNodes(forgers);
    } catch (error) {
      console.error('Error fetching nodes:', error);
      message.error('Error fetching nodes');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNodes();
  }, []);

  const fetchStakeList = async (nodeKey, pubkey, vrf1, vrf2) => {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abi, contractAddress);
      const response = await contract.methods.getPagedForgersStakesByForger(pubkey, vrf1, vrf2, START_INDEX, PAGE_SIZE).call();
      const stakes = response['1'].map((stake, index) => ({
        key: `delegate-${nodeKey}-${index}`,
        delegator: stake.delegator,
        amount: web3.utils.fromWei(stake.stakedAmount, 'ether'),
      }));

      setNodes(prevNodes => {
        return prevNodes.map(n => {
          if (n.key === nodeKey) {
            return { ...n, stakeList: stakes.length ? stakes : [], hasChildren: true }; // 更新标志位
          }
          return n;
        });
      });
    } catch (error) {
      console.error('Error fetching stake list:', error);
      message.error('Error fetching stake list');
    }
  };

  const handleExpand = async (expanded, record) => {
    if (expanded && !record.hasChildren) { // 使用标志位判断是否需要加载子节点
      await fetchStakeList(record.key, record.signPubKey, record.vrf1, record.vrf2);
    }
  };

  const renderShortenedText = (text) => text ? `0x...${text.slice(-6)}` : '';

  const renderSignPubKey = (text) => {
    if (!text) {
      return '';
    }
    if (text === "0x0e5b543bdf50c728167909a789bf83ad12ab4ebef89277bb90a91ff4f95c22de") {
      return <span style={{ color: 'red' }}><HomeOutlined />{`0x...${text.slice(-6)}`} (zennode.org)</span>;
    }
    if (text === "0xfade374878583cc77cf6c27172fcab9a9964c9fe50e523dc8f08bee560119589") {
      return <span>{`0x...${text.slice(-6)}`} (eonnode.com)</span>
    }
    return <span><HomeOutlined /> {`0x...${text.slice(-6)}`}</span>;
  };

  const renderRewardAddr = (text) => {
    if (text === '0x0000000000000000000000000000000000000000') {
      text = '';
    }
    return <span>{text}</span>
  }

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: <FormattedMessage id="signPubKey" defaultMessage="SignPubKey" />,
      dataIndex: 'signPubKey',
      key: 'signPubKey',
      render: renderSignPubKey,
    },
    {
      title: 'Vrf1',
      dataIndex: 'vrf1',
      key: 'vrf1',
      render: text => <span>{renderShortenedText(text)}</span>,
    },
    {
      title: 'Vrf2',
      dataIndex: 'vrf2',
      key: 'vrf2',
      render: text => <span>{text}</span>,
    },
    {
      title: <FormattedMessage id="rewardShare" defaultMessage="RewardShare" />,
      dataIndex: 'rewardShare',
      key: 'rewardShare',
      align: 'right', // 右对齐
      sorter: (a, b) => a.rewardShare - b.rewardShare, // 排序规则
      defaultSortOrder: 'descend', // 默认排序
      render: text => <span>{(text / 10).toFixed(1)} <PercentageOutlined /></span>,
    },
    {
      title: <FormattedMessage id="rewardAddress" defaultMessage="RewardAddress" />,
      dataIndex: 'rewardAddress',
      key: 'rewardAddress',
      render: renderRewardAddr,
    },
  ];

  const expandedColumns = [
    { 
      title: <FormattedMessage id="delegateAddr" defaultMessage="Delegator's Address" />,
      dataIndex: 'delegator', key: 'delegator' },
    { 
      title: <FormattedMessage id="totalStakeAmount" defaultMessage="Amount Of Delegation" />,
      dataIndex: 'amount', key: 'amount',
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount), // 排序规则
      defaultSortOrder: 'descend', // 默认排序
    },
  ];

  const expandedRowRenderCus = (record) => {
    return record.stakeList ? (
      <Table
        columns={expandedColumns}
        dataSource={record.stakeList}
        pagination={false}
        rowKey={record => record.key} // 确保子表的rowKey唯一
      />
    ) : (
      <Spin />
    );
  };

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: '20px' }}>
        <h2><FormattedMessage id="allForgerList" /></h2>
      </div>
      <Table
        columns={columns}
        dataSource={nodes}
        expandable={{
          expandedRowRender: expandedRowRenderCus,
          onExpand: handleExpand,
        }}
        loading={loading}
        rowKey={record => record.key}
      />
    </div>
  );
};

export default App;
