import React, { useState, useEffect, useContext, useRef } from 'react';
import { Steps, message } from 'antd';
import '../index.css';
import Web3 from 'web3';
import { MetamaskContext } from '../context/MetamaskContext';
import { FormattedMessage } from 'react-intl';

import contractABI from '../abi/forger_stake_v2.json';


const { Step } = Steps;



const Home = () => {
  const { balance, setBalance, account, setAccount } = useContext(MetamaskContext);

  const [web3, setWeb3] = useState(null);

  const inputRef = useRef(null); // 创建对输入框的引用


  const contractAddress = "0x0000000000000000000022222222222222222333";

  const formatBalance = (balance) => {
    const numericBalance = parseFloat(balance);
    // 检查转换后的balance是否是NaN（不是一个数字），如果是，则返回0
    if (isNaN(numericBalance)) {
      return 0;
    }
    return Math.floor(numericBalance * 100) / 100;
  };

  // 质押事件响应
  const handleSubmit = async (e) => {
    e.preventDefault();
    const amountToStake = e.target.amount.value; // 获取用户输入的质押金额
    handleDelegate(amountToStake);
  };

  // 发起质押
  const handleDelegate = async (amount) => {

    message.info('Metamask is being opened, Please wait when the operation is complete', 3);

    // 由于vrfPublicKey恰好是bytes32的长度，不需要特别处理提取lastByte
    // 如果智能合约的delegate方法真的需要一个lastByte参数，你可能需要重新检查这个需求
    try {
      const contract = new web3.eth.Contract(contractABI, contractAddress, {from: account});
      const value = web3.utils.toWei(String(amount), 'ether');
      const nonce = await web3.eth.getTransactionCount(account);

      console.log(`发送代理交易...Account: ${account}  Nonce: ${nonce}`);      
      // const value = amount *10**18;
      
      // TEST
      // const blockSignPublicKey = "0x" + "41f84a648a2d40e6a0a0000694865b18de17626d521b03c544fe6524c2cf5d9d";
      // const forgerVrfPublicKey = "0x" + "98b15827da3fdccdfc30207c3662bbe4692c7b34baea8c5abe0cd14cd235622b80";
      
      // MAIN
      const blockSignPublicKey = "0x" + "0e5b543bdf50c728167909a789bf83ad12ab4ebef89277bb90a91ff4f95c22de";
      const forgerVrfPublicKey = "0x" + "3ae7a97ec8bb03a35a15d014de4695c2b97cde011a7d174966bfb989aeef660980";

      const first32BytesForgerVrfPublicKey = forgerVrfPublicKey.substring(0, 66);
      const lastByteForgerVrfPublicKey = "0x" + forgerVrfPublicKey.substring(66, 68);

      // await contract.methods.delegate(blockSignPublicKey,first32BytesForgerVrfPublicKey,lastByteForgerVrfPublicKey).send({value: value, nonce: nonce}).then(console.log);

      message.info('Delegating ... 委托中，请等待合约确认', 5);
      await contract.methods.delegate(blockSignPublicKey, first32BytesForgerVrfPublicKey, lastByteForgerVrfPublicKey)
        .send({ value, nonce })
        .then(async (receipt) => {
          console.log(receipt);
          // 更新余额
          const balance = await web3.eth.getBalance(account);
          setBalance(web3.utils.fromWei(balance, 'ether'));
          message.success('Success-质押完成', 3);
          // 清空输入框
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        });

    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  // 初始化web3和contract
  useEffect(() => {
    if (typeof window.ethereum === 'undefined') {
      message.error("MetaMask is not installed");
      return;
    }
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]);
        } catch (error) {
          console.error("User denied account access or error occurred", error);
        }
      } else {
        console.error("MetaMask not installed");
      }
    };

    initWeb3();
  }, [setAccount]);



  return (

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', color: '#ffffff' }}>
        <div style={{ flex: 1, padding: '10px 0px' }}>
        <h2><FormattedMessage id="staking.begin" /></h2>
            <Steps direction="vertical" current={-1} style={{paddingTop: '40px'}}>
              <Step
                title={<FormattedMessage id="whyStake.title" />}
                description={
                  <span>
                    <FormattedMessage 
                      id="whyStake.description"
                      values={{
                        link: <a href="https://docs.horizen.io/horizen_eon/staking/what_is_staking" target="_blank" rel="noopener noreferrer">
                                <FormattedMessage id="stakingGuide" />
                              </a>
                      }} />
                  </span>
                }
              />
              <Step
                title={<FormattedMessage id="howToStake.title" />}
                description={
                  <FormattedMessage
                    id="howToStake.description"
                  />
                }
              />

              <Step
                title={<FormattedMessage id="canWithdrawAnytime.title" />}
                description={
                  <span>
                    <FormattedMessage
                      id="canWithdrawAnytime.description"
                      values={{
                        link: <a href="https://docs.horizen.io/horizen_eon/connect/backward_transfer" target="_blank" rel="noopener noreferrer">
                                <FormattedMessage id="withdrawalGuide" />
                              </a>
                      }}
                    />
                  </span>
                }
              />

              <Step
                title={<FormattedMessage id="platform.health" />}
                description={
                  <FormattedMessage id="health.description" />
                }
              />

              <Step
                title={<FormattedMessage id="fees.title" />}
                description={
                  <FormattedMessage id="fees.description" /> 
                }
              />

              
            </Steps>
        </div>

        <div style={{ flex: 1, padding: '10px 0px' }}>
          <div style={{ width: '570px', minHeight: '700px', 
                        padding: '48px 24px', marginLeft: 'auto', marginRight: '70px',
                        backgroundColor: '#151528', borderRadius: '8px', color: '#FFFFFF' }}>
            
            <h1><FormattedMessage id="staking.start" /></h1>

            <div style={{marginTop:'40px'}}>
              <p style={{}}><FormattedMessage id="staking.enterAmount" /></p>
              <p><FormattedMessage id="staking.transactionSent" /></p>
              <p><FormattedMessage id="staking.seeStakeTotal" /></p>
              <form onSubmit={handleSubmit}>
                <div className="form-group" style={{ marginTop:'40px', marginBottom: '10px' }}>
                  <label htmlFor="walletBalance"><FormattedMessage id="staking.walletBalance" /></label>
                  <input
                    type="text"
                    id="walletBalance"
                    value={formatBalance(balance) + ' ZEN'}
                    disabled
                    style={{ width: '100%', height: '40px', padding: '2px' }}
                  />
                </div>
                <div className="form-group" style={{ marginTop:'20px', marginBottom: '20px' }}>
                  <label htmlFor="amount"><FormattedMessage id="staking.stakeAmount" /></label>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    placeholder="Enter stake amount"
                    ref={inputRef} // 绑定输入框引用
                    style={{ width: '100%', height: '40px', padding: '2px' }}
                  />
                </div>
                <button type="submit" className='custom-button' style={{width:'100%', height: '42px', marginTop:'20px'}}>
                  <FormattedMessage id="staking.stakeButton" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
  );

};

export default Home;