import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import '../index.css';

import { FormattedMessage } from 'react-intl';

const { Sider } = Layout;

const MenuComponent = () => {
  const location = useLocation(); // 获取当前路径
  const selectedKeys = [location.pathname]; // 根据当前路径设置选中的菜单项
  const items = [
    {
      key: '/',
      label: <Link to="/"><FormattedMessage id="menu.connect" /></Link>,
    },
    {
      key: '/delegate',
      label: <Link to="/delegate"><FormattedMessage id="menu.delegate" /></Link>,
    },
    {
      key: '/claim',
      label: <Link to="/claim"><FormattedMessage id="menu.claim" /></Link>,
    },
    {
      key: '/withdraw',
      label: <Link to="/withdraw"><FormattedMessage id="menu.withdraw" /></Link>,
    },
    {
      key: '/transfer',
      label: <Link to="/transfer"><FormattedMessage id="menu.transfer" /></Link>,
    },
    {
      key: '/query',
      label: <Link to="/query"><FormattedMessage id="menu.query" /></Link>,
    },
  ];

  return (
    <Sider width={280} style={{ height:'100%'}}>
      <Menu
        mode="inline"
        selectedKeys={selectedKeys} // 设置选中的菜单项
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
        theme="dark"
        items={items}
      />
    </Sider>
  );
};

export default MenuComponent;
