import React from 'react';
import { Steps, Image } from 'antd';
import '../index.css';

import { FormattedMessage } from 'react-intl';

const { Step } = Steps;

const Home = () => (

    <div  style={{ display: 'flex', flexDirection: 'row', width: '100%', color: '#ffffff' }}>

      <div style={{ flex: 1, padding: '10px 0px' }}>
               
        <h2><FormattedMessage id="startUsingHorizenEON" /></h2>

        <Steps direction="vertical" current={-1} style={{paddingTop: '40px'}}>
          <Step
            title={<FormattedMessage id="installWallet" />}
            description={
              <span>
                <FormattedMessage
                  id="installWalletDescription"
                  values={{
                    link: (
                      <a
                        href="https://docs.horizen.io/horizen_eon/connect/get_a_wallet"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="installationGuide" />
                      </a>
                    ),
                  }}
                />
              </span>
            }
          />
          <Step
            title={<FormattedMessage id="configureHorizenEONNetwork" />}
            description={
              <span>
                <FormattedMessage
                  id="configureHorizenEONNetworkDescription"
                  values={{
                    link: (
                      <a
                        href="https://docs.horizen.io/horizen_eon/connect/connect_your_wallet"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="configurationGuide" />
                      </a>
                    ),
                  }}
                />
              </span>
            }
          />
          <Step
            title={<FormattedMessage id="depositZEN" />}
            description={
              <span>
                <FormattedMessage
                  id="depositZENDescription"
                  values={{
                    link: (
                      <a
                        href="https://docs.horizen.io/horizen_eon/connect/forward_transfer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="depositGuide" />
                      </a>
                    ),
                  }}
                />
              </span>
            }
          />
          <Step
            title={<FormattedMessage id="startStaking" />}
            description={<FormattedMessage id="startStakingDescription" />}
          />
        </Steps>
      </div>

      <div style={{ flex: 1, padding: '10px' }}>
            <Image width={800} height={516} src="./img/metamask_net.png" />

            </div>
    </div>
);

export default Home;
