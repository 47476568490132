import React from 'react';
import { Layout } from 'antd';
import '../index.css';

import { IntlProvider, FormattedMessage } from 'react-intl';
import en from '../locales/en.json';
import zh from '../locales/zh.json';

const messages = { en, zh };

let language = 'en'; // 设置默认语言为 'en'

if (navigator.language) {
  const langArray = navigator.language.split(/[-_]/);
  if (langArray.length > 0 && messages[langArray[0]]) {
    language = langArray[0];
  }
}

const { Footer } = Layout;

const FooterComponent = () => (
  <IntlProvider locale={language} messages={messages[language]}>
    <Footer className="footer">
      <div>
         <a target='_blank' rel="noreferrer" href="https://eon-explorer.horizenlabs.io/tx/0x222b243b333f89a55d3892b54ca834a6c09435b7fcf08d7b0a3f89d5b2844662?tab=logs">
            {<FormattedMessage id="footer.detail" />}
          </a>
        <p> © 2024 Created by  {<FormattedMessage id="footer.copyright" />}</p>
        <p>{<FormattedMessage id="footer.disclaimer" />}</p>
      </div>
    </Footer>
  </IntlProvider>
);

export default FooterComponent;
