import React, { useState, useEffect, useContext } from 'react';
import { Steps, message } from 'antd';
import '../index.css';
import { MetamaskContext } from '../context/MetamaskContext';
import Web3 from 'web3';

import { FormattedMessage } from 'react-intl';

import contractABI from '../abi/forger_stake_v2.json';

const { Step } = Steps;

const Home = () => {

  const contractAddress = "0x0000000000000000000022222222222222222333";

  const { account, setAccount, stakeAmount, setStakeAmount } = useContext(MetamaskContext);
  const [web3, setWeb3] = useState(null);

  const handleWithdraw = async (amount) => {
    if (!web3) {
      message.error('Web3 is not initialized');
      return;
    }
    
    message.info('Metamask is being opened, Please wait when the operation is complete', 5);
    try {
      const contract = new web3.eth.Contract(contractABI, contractAddress, { from: account });
      const value = web3.utils.toWei(String(amount), 'ether');
      const nonce = await web3.eth.getTransactionCount(account);

      console.log("发送代理交易...");
      // MAIN
      const blockSignPublicKey = "0x" + "0e5b543bdf50c728167909a789bf83ad12ab4ebef89277bb90a91ff4f95c22de";
      const forgerVrfPublicKey = "0x" + "3ae7a97ec8bb03a35a15d014de4695c2b97cde011a7d174966bfb989aeef660980";

      const first32BytesForgerVrfPublicKey = forgerVrfPublicKey.substring(0, 66);
      const lastByteForgerVrfPublicKey = "0x" + forgerVrfPublicKey.substring(66, 68);

      console.log('Account ' + account);
      console.log('nonce ' + nonce);
      await contract.methods.withdraw(blockSignPublicKey, first32BytesForgerVrfPublicKey, lastByteForgerVrfPublicKey, value).send({ value: 0, nonce: nonce }).then(console.log);

      message.success('提取质押完成', 3);

    } catch (error) {
      console.error("Error:", error.message);
      message.error('The execution failed, please try again later', 8);

    }
  }

  const handleSubmitWithdraw = async (e) => {
    e.preventDefault();
    const amountToWithdraw = e.target.amount.value;
    handleWithdraw(amountToWithdraw);
  }

  const fetchStakes = async (address) => {
    if (!web3 || !address || address === '0x00000') {
      console.log("ignore...");
      return; // 如果地址为空，则直接返回，不执行后续的fetch操作
    }
    try {
      const contract = new web3.eth.Contract(contractABI, contractAddress);
      contract.methods.getPagedForgersStakesByDelegator(address, 0, 10).call().then(response => {
        const value = web3.utils.fromWei(String(response["1"][0][3]), 'ether');
        console.log(value);
        setStakeAmount(value);
      });
    } catch (error) {
      console.error('Error fetching statistics:', error);
      message.error('获取数据失败');
    }
  };

  useEffect(() => {
    const initWeb3 = async () => {
      if (typeof window.ethereum === 'undefined') {
        message.error("MetaMask is not installed");
        return;
      }

      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3Instance.eth.getAccounts();
        setAccount(accounts[0]);
      } catch (error) {
        console.error("User denied account access or error occurred", error);
      }
    };

    initWeb3();
  }, [setAccount, setStakeAmount]);

  useEffect(() => {
    if (account) {
      fetchStakes(account);
    }
  }, [account, web3]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', color: '#ffffff' }}>
      <div style={{ flex: 1, padding: '10px 0px' }}>
        <h2><FormattedMessage id="withdrawMyZEN" /></h2>
        <Steps direction="vertical" current={-1} style={{ paddingTop: '40px' }}>
          <Step
            title={<FormattedMessage id="verifyWallet" />}
            description={
              <span>
                <FormattedMessage id="verifyWalletDescription" />
              </span>
            }
          />
          <Step
            title={<FormattedMessage id="inputAmount" />}
            description={
              <span>
                <FormattedMessage id="inputAmountDesc" />
              </span>
            }
          />
          <Step
            title={<FormattedMessage id="startWithdrawal" />}
            description={<FormattedMessage id="startWithdrawalDescription" />}
          />
        </Steps>
      </div>

      <div style={{ flex: 1, padding: '10px 0px' }}>
        <div style={{
          width: '570px', minHeight: '700px',
          padding: '48px 24px', marginLeft: 'auto', marginRight: '70px',
          backgroundColor: '#151528', borderRadius: '8px', color: '#FFFFFF'
        }}>
          <h2><FormattedMessage id="withdrawStake.title" /></h2>
          <p><FormattedMessage id="withdrawStake.description1" /></p>
          <p><FormattedMessage id="withdrawStake.description2" /></p>

          <form onSubmit={handleSubmitWithdraw}>
            <div className="form-group" style={{ paddingTop: '40px', marginBottom: '10px' }}>
              <label htmlFor="walletBalance">
                <FormattedMessage id="stakeAddress" />
              </label>
              <input
                type="text"
                id="walletAccount"
                name="walletAccount"
                value={account}
                disabled
                style={{ width: '98%', height: '40px', padding: '2px', fontSize: '16px' }}
              />
            </div>

            <div className="form-group" style={{ marginBottom: '20px' }}>
              <label htmlFor="walletBalance">
                <FormattedMessage id="withdraw.stakeAmount" />
              </label>
              <input
                type="text"
                id="stakeAmount"
                name="stakeAmount"
                value={stakeAmount}
                disabled
                style={{ width: '98%', height: '40px', padding: '2px', fontSize: '16px' }}
              />
            </div>

            <div className="form-group" style={{ marginBottom: '20px' }}>
              <label htmlFor="stakeAmount" >
                <FormattedMessage id="withdrawStake.choose" />
              </label>
              <input
                type="number"
                id="amount"
                name="amount"
                style={{ width: '98%', height: '40px', padding: '2px', fontSize: '16px' }}
              />
            </div>

            <button type="submit" className='custom-button' style={{ width: '100%', height: '42px', marginTop: '20px' }} >
              Withdraw
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
