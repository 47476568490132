import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const MetamaskContext = createContext();

export const MetamaskProvider = ({ children }) => {
  const [account, setAccount] = useState('0x00000');
  const [network, setNetwork] = useState('Unknown');
  const [balance, setBalance] = useState(0.0);
  const [stakeAmount, setStakeAmount] = useState(0.0);

  return (
    <MetamaskContext.Provider value={{ account, setAccount, network, setNetwork, balance, setBalance, stakeAmount, setStakeAmount }}>
      {children}
    </MetamaskContext.Provider>
  );
};

MetamaskProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
