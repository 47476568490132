import React, {useState} from 'react';
import { Button, message } from 'antd';
import copy from 'copy-to-clipboard';
import './Banner.css'; // 创建一个 CSS 文件用于样式

import { IntlProvider, FormattedMessage } from 'react-intl';
import en from '../locales/en.json';
import zh from '../locales/zh.json';

const messages = { en, zh };
// const language = navigator.language.split(/[-_]/)[0]; // 例如 'en' 或 'zh'
let language = 'en'; // 设置默认语言为 'en'

if (navigator.language) {
  const langArray = navigator.language.split(/[-_]/);
  if (langArray.length > 0 && messages[langArray[0]]) {
    language = langArray[0];
  }
}



const Banner = () => {
  const [inviteMessage, setInviteMessage] = useState('');
  const handleInviteButtonClick = () => {
    const link = 'https://zennode.org/';
    if (language == "zh") {
      setInviteMessage(`我在Zennode.org参与Horizen EON质押，当前年化收益7%，邀请你来参加 ${link}`);
      message.success('邀请文案已复制到剪贴板.');
    } else {
      setInviteMessage(`I am participating in Horizen EON staking on Zennode.org, with a current annual yield of 7%, and I invite you to join us at ${link}.`);
      message.success('The invitation message has been copied to the clipboard.');    
    }
    copy(inviteMessage);
  };
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className="banner-container">
        <div className="banner-content">
          <span><FormattedMessage id={"banner.shareMsg"} /></span>
          <Button className='banner-button' type="primary" onClick={handleInviteButtonClick} ><FormattedMessage id={"banner.shareNow"} /></Button>
        </div>
      </div>
    </IntlProvider>
    
  );
};

export default Banner;
